import { useEffect, useState, memo } from 'react';
import { ticker } from '../../utils/ticker';
import styles from './loading-spinner.module.css';

export type LoadingSpinnerProps = {
  isActive?: boolean;
};

export function LoadingSpinner({ isActive = true }: LoadingSpinnerProps) {
  const [isReady, setReady] = useState(false);

  // We need to give the browser time to render the component before animating
  // it in. That's why we delay the state change until the next tick.
  useEffect(() => {
    const listenerId = ticker.once('write', () => {
      setReady(true);
    });

    return () => {
      ticker.off('write', listenerId);
    };
  }, []);

  return <div className={`${styles.root} ${isReady && isActive ? styles.isActive : ''}`}></div>;
}

export const LoadingSpinnerMemo = memo(LoadingSpinner);
