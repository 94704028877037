import { STORAGE_KEY_SCROLL_RESTORATION, SCROLL_RESTORATION_SELECTORS } from '../constants.mjs';

export interface ScrollRestorationPosition {
  scrollTop: number;
  scrollLeft: number;
}

export interface ScrollRestorationStorage {
  [pathname: string]: {
    [elementId: string]: ScrollRestorationPosition;
  };
}

export function getScrollableElements() {
  return new Set(
    Array.from(
      document.querySelectorAll<HTMLElement>(SCROLL_RESTORATION_SELECTORS.join(',')),
    ).filter((element) => element.id && element instanceof HTMLElement),
  );
}

function getScrollPositions(): Record<string, ScrollRestorationPosition> {
  const positions: Record<string, ScrollRestorationPosition> = {};
  const scrollableElements = getScrollableElements();
  scrollableElements.forEach((element) => {
    positions[element.id] = {
      scrollTop: element.scrollTop,
      scrollLeft: element.scrollLeft,
    };
  });
  return positions;
}

export function saveScrollPositions(pathname: string) {
  const positions = getScrollPositions();

  const currentData = JSON.parse(
    sessionStorage.getItem(STORAGE_KEY_SCROLL_RESTORATION) || '{}',
  ) as ScrollRestorationStorage;

  const nextData: ScrollRestorationStorage = {
    ...currentData,
    [pathname]: positions,
  };

  sessionStorage.setItem(STORAGE_KEY_SCROLL_RESTORATION, JSON.stringify(nextData));
}

export function restoreScrollPositions(pathname: string) {
  const scrollPositions = JSON.parse(
    sessionStorage.getItem(STORAGE_KEY_SCROLL_RESTORATION) || '{}',
  ) as ScrollRestorationStorage;

  // Get all scrollable elements.
  const scrollableElements = getScrollableElements();

  // If we have stored scroll positions for the current path, restore them.
  if (scrollPositions[pathname]) {
    const positions = scrollPositions[pathname];
    Object.entries(positions).forEach(([elementId, position]) => {
      const element = document.getElementById(elementId);
      if (element) {
        // Remove element from scrollable elements.
        scrollableElements.delete(element);

        // Restore the scroll position.
        element.scrollTo({
          top: position.scrollTop,
          left: position.scrollLeft,
          behavior: 'instant',
        });
      }
    });
  }

  // For all remaining scrollable elements, scroll the element to start
  // position.
  scrollableElements.forEach((element) => {
    element.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  });
}

export function saveTargetScrollPosition(pathname: string, targetId: string) {
  const element = document.getElementById(targetId);
  if (!element) return;

  const currentData = JSON.parse(
    sessionStorage.getItem(STORAGE_KEY_SCROLL_RESTORATION) || '{}',
  ) as ScrollRestorationStorage;

  const nextData: ScrollRestorationStorage = {
    ...currentData,
    [pathname]: {
      ...currentData[pathname],
      [targetId]: {
        scrollTop: element.scrollTop,
        scrollLeft: element.scrollLeft,
      },
    },
  };

  sessionStorage.setItem(STORAGE_KEY_SCROLL_RESTORATION, JSON.stringify(nextData));
}

export function restoreTargetScrollPosition(pathname: string, targetId: string) {
  const scrollPositions = JSON.parse(
    sessionStorage.getItem(STORAGE_KEY_SCROLL_RESTORATION) || '{}',
  ) as ScrollRestorationStorage;

  if (!scrollPositions[pathname]) return;

  const positions = scrollPositions[pathname];
  const targetPosition = positions[targetId];

  if (!targetPosition) return;

  const element = document.getElementById(targetId);
  if (!element) return;

  element.scrollTo({
    top: targetPosition.scrollTop,
    left: targetPosition.scrollLeft,
    behavior: 'instant',
  });
}

export function clearScrollPositions(pathname: string) {
  const scrollPositions = JSON.parse(
    sessionStorage.getItem(STORAGE_KEY_SCROLL_RESTORATION) || '{}',
  ) as ScrollRestorationStorage;
  delete scrollPositions[pathname];
  sessionStorage.setItem(STORAGE_KEY_SCROLL_RESTORATION, JSON.stringify(scrollPositions));
}
