import mixpanel from 'mixpanel-browser';

let cachedUserId: string | undefined;

export async function initMixpanel() {
  const userId = await getMixpanelUserId();

  if (!userId) {
    return;
  }
  mixpanel.init('be2a1cf2a213df8e468a78894b9d5a57', {
    track_pageview: 'url-with-path',
  });

  mixpanel.identify(userId);
}

async function getMixpanelUserId(): Promise<string | undefined> {
  if (cachedUserId === undefined) {
    try {
      // @ts-ignore
      const ray = new RayAPI();
      const userId = await ray.getMixpanelUserId();

      cachedUserId = userId;

      return userId || undefined;
    } catch (e) {
      cachedUserId = '';
      return undefined;
    }
  }

  return cachedUserId;
}

export async function sendMixPanelEvent(eventType: string, payload?: { [key: string]: any }) {
  if (typeof window === 'undefined' || !cachedUserId) {
    return;
  }

  mixpanel.track(eventType, {
    ...payload,
    time: Date.now(),
  });
}
