import { forwardRef, useMemo, useCallback, memo, ComponentProps } from 'react';
import { useRouter } from 'next/router';
import { useApolloClient, ApolloClient } from '@apollo/client';
import { GetCategoryBackgroundImage } from '../../graphql-queries';
import { sanitizeRouterPathname } from '../../utils/sanitize-router-pathname';
import { preloadImage } from '../../utils/preload-image';
import { clearScrollPositions } from '../../utils/scroll-restoration';
import { IMAGE_SIZES, STICKY_SEARCH_PARAMS } from '../../constants.mjs';

// Cache of prefetched URLs to avoid duplicate prefetches.
const prefetchedUrls = new Set<string>();

function isRelativeUrl(href: string): boolean {
  // This considers relative URLs as those starting with '/', '#', or '?'.
  return href.startsWith('/') || href.startsWith('#') || href.startsWith('?');
}

function isModifiedEvent(event: React.MouseEvent): boolean {
  const eventTarget = event.currentTarget as HTMLAnchorElement | SVGAElement;
  const target = eventTarget.getAttribute('target');
  return (
    (target && target !== '_self') ||
    event.metaKey ||
    event.ctrlKey ||
    event.shiftKey ||
    event.altKey || // triggers resource download
    (event.nativeEvent && event.nativeEvent.which === 2)
  );
}

async function preloadCategoryImage(url: string, apolloClient: ApolloClient<any>) {
  // Make sure the url is a category page.
  if (!url.startsWith('/category/')) return;

  // Get the category id from the url.
  const [categoryId] = url.substring('/category/'.length).split(/[?#/]/);
  if (!categoryId) return;

  // Fetch the category data.
  const data = await apolloClient.query({
    query: GetCategoryBackgroundImage,
    variables: { categoryId },
  });
  if (!data) return;

  // Try to parse the image url from the data.
  const imageUrl = data?.data?.category?.[0]?.backgroundImage?.url;
  if (!imageUrl) return;

  // Preload the image.
  preloadImage(imageUrl, IMAGE_SIZES.CategoryHeader);
}

const useStickyParams = (href: string): string => {
  const router = useRouter();
  return useMemo(() => {
    // If not relative, return as-is.
    if (!isRelativeUrl(href)) return href;

    // For relative URLs, we attempt to attach sticky search params.
    try {
      const url = new URL(href, 'https://foo.com'); // dummy origin for parsing
      STICKY_SEARCH_PARAMS.forEach((param) => {
        if (param in router.query && !url.searchParams.has(param)) {
          const value = router.query[param] || '';
          url.searchParams.set(param, Array.isArray(value) ? value[0] || '' : value);
        }
      });
      return `${url.pathname}${url.search}${url.hash}`;
    } catch (e) {
      // If URL parsing fails for some reason, just return original href.
      return href;
    }
  }, [href, router.query]);
};

export type InternalLinkProps = Omit<ComponentProps<'a'>, 'ref'> & {
  prefetch?: boolean | (() => void);
  replace?: boolean;
  shallow?: boolean;
};

export const InternalLink = forwardRef<HTMLAnchorElement, InternalLinkProps>(
  function InternalLinkComponent(
    {
      href = '',
      draggable = false,
      prefetch = true,
      replace = false,
      shallow = false,
      children = null,
      onClick,
      onMouseEnter,
      onFocus,
      ...restProps
    },
    ref,
  ) {
    const apolloClient = useApolloClient();
    const router = useRouter();
    const hrefWithStickyParams = useStickyParams(href);

    const handlePrefetch = useCallback(() => {
      // Preload the category header image.
      preloadCategoryImage(hrefWithStickyParams, apolloClient);

      // If prefetch is explicitly disabled, do nothing.
      if (prefetch === false) return;

      // If it's not a relative URL, it doesn't make sense to prefetch because
      // router.prefetch is for internal routes only.
      if (!isRelativeUrl(hrefWithStickyParams)) return;

      // If prefetch is a function, call it. In this case we want to skip the
      // prefetch cache also.
      if (typeof prefetch === 'function') {
        prefetch();
        return;
      }

      // If the link points to the current pathname, no need to prefetch.
      try {
        if (
          sanitizeRouterPathname(hrefWithStickyParams) === sanitizeRouterPathname(router.pathname)
        ) {
          return;
        }
      } catch {}

      // If this url has already been prefetched, do nothing.
      if (prefetchedUrls.has(hrefWithStickyParams)) return;

      // Add to prefetch cache.
      prefetchedUrls.add(hrefWithStickyParams);

      // Default prefetch.
      router.prefetch(hrefWithStickyParams).catch((e) => {
        console.error(`Failed to prefetch page data: ${hrefWithStickyParams}`);
        console.error(e);
      });
    }, [prefetch, hrefWithStickyParams, router, apolloClient]);

    const handleMouseEnter = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (onMouseEnter) onMouseEnter(e);
        handlePrefetch();
      },
      [onMouseEnter, handlePrefetch],
    );

    const handleFocus = useCallback(
      (e: React.FocusEvent<HTMLAnchorElement>) => {
        if (onFocus) onFocus(e);
        handlePrefetch();
      },
      [onFocus, handlePrefetch],
    );

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (onClick) onClick(e);

        // If default is already prevented, do nothing.
        if (e.defaultPrevented) return;

        // If absolute URL, let the browser handle navigation normally.
        if (!isRelativeUrl(hrefWithStickyParams)) {
          return;
        }

        // Check for modifier keys or non-left-click (to allow open in new tab,
        // etc.)
        if (isModifiedEvent(e)) {
          return;
        }

        // Prevent full page reload for relative (internal) URLs.
        e.preventDefault();

        // Clear scroll positions. We want to always have the scrollable
        // elements of the next page start at their initial positions when
        // navigating via internal link.
        clearScrollPositions(sanitizeRouterPathname(hrefWithStickyParams));

        // Perform client-side navigation
        if (replace) {
          router.replace(hrefWithStickyParams, undefined, { shallow });
        } else {
          router.push(hrefWithStickyParams, undefined, { shallow });
        }
      },
      [onClick, router, hrefWithStickyParams, replace, shallow],
    );

    return (
      <a
        ref={ref}
        href={hrefWithStickyParams}
        draggable={draggable}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onFocus={handleFocus}
        {...restProps}
      >
        {children}
      </a>
    );
  },
);

export const InternalLinkMemo = memo(InternalLink);
