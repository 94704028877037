import { graphql } from './.gql';

export const GetIndexPageData = graphql(`
  query GetIndexPageData {
    sliderItems: featuredItems(
      where: {
        AND: [{ group: { equals: "slider-items" } }, { item: { status: { equals: published } } }]
      }
      orderBy: { weight: asc }
    ) {
      item {
        id
        type
        name
        shortDescription
        launchUrl
        categories(orderBy: { weight: asc }) {
          id
          name
        }
        logoImage {
          url
          width
          height
        }
        landscapeThumbnail {
          url
        }
        backgroundImage {
          url
        }
        backgroundVideo {
          url
        }
      }
    }

    trendingItems: featuredItems(
      where: {
        AND: [{ group: { equals: "trending-items" } }, { item: { status: { equals: published } } }]
      }
      orderBy: { weight: asc }
    ) {
      item {
        id
        type
        name
        shortDescription
        launchUrl
        categories(orderBy: { weight: asc }) {
          id
          name
        }
        logoImage {
          url
          width
          height
        }
        portraitThumbnail {
          url
        }
      }
    }

    featuredItems: featuredItems(
      where: {
        AND: [{ group: { equals: "featured-items" } }, { item: { status: { equals: published } } }]
      }
      orderBy: { weight: asc }
    ) {
      item {
        id
        type
        name
        shortDescription
        launchUrl
        categories(orderBy: { weight: asc }) {
          id
          name
        }
        landscapeThumbnail {
          url
        }
        thumbnailVideo {
          url
        }
      }
    }

    featuredCategories(orderBy: { weight: asc }) {
      category {
        id
        name
        landscapeThumbnail {
          url
        }
        portraitThumbnail {
          url
        }
      }
    }

    collections(where: { status: { equals: published } }, orderBy: { weight: asc }) {
      id
      name
      items(where: { status: { equals: published } }, orderBy: { publishedAt: desc }) {
        id
        type
        name
        shortDescription
        launchUrl
        categories(orderBy: { weight: asc }) {
          id
          name
        }
        landscapeThumbnail {
          url
        }
        portraitThumbnail {
          url
        }
        backgroundImage {
          url
        }
      }
    }
  }
`);

export const GetSearchPageData = graphql(`
  query GetSearchPageData {
    trendingItems: featuredItems(
      where: {
        AND: [{ group: { equals: "trending-items" } }, { item: { status: { equals: published } } }]
      }
      orderBy: { weight: asc }
    ) {
      item {
        id
        type
        name
        shortDescription
        launchUrl
        categories(orderBy: { weight: asc }) {
          id
          name
        }
        landscapeThumbnail {
          url
        }
        thumbnailVideo {
          url
        }
      }
    }
  }
`);

export const GetGamesPageData = graphql(`
  query GetGamesPageData {
    featuredCategories(orderBy: { weight: asc }) {
      category {
        id
        name
        portraitThumbnail {
          url
        }
      }
    }

    categories(orderBy: { weight: asc }) {
      id
      name
    }

    items(where: { status: { equals: published } }, orderBy: { publishedAt: desc }) {
      id
      type
      name
      shortDescription
      launchUrl
      categories(orderBy: { weight: asc }) {
        id
        name
      }
      landscapeThumbnail {
        url
      }
      thumbnailVideo {
        url
      }
    }
  }
`);

export const GetCategoryPageData = graphql(`
  query GetCategoryPageData($categoryId: ID!) {
    categories(orderBy: { weight: asc }) {
      id
      name
    }

    category: categories(where: { id: { equals: $categoryId } }) {
      id
      name
      backgroundImage {
        url
      }
    }

    items(
      where: {
        AND: [
          { categories: { some: { id: { equals: $categoryId } } } }
          { status: { equals: published } }
        ]
      }
      orderBy: { publishedAt: desc }
    ) {
      id
      type
      name
      shortDescription
      launchUrl
      categories(orderBy: { weight: asc }) {
        id
        name
      }
      landscapeThumbnail {
        url
      }
      thumbnailVideo {
        url
      }
    }
  }
`);

export const GetRecentlyPlayedItems = graphql(`
  query GetRecentlyPlayedItems($itemIds: [ID!]) {
    items(where: { AND: [{ id: { in: $itemIds } }, { status: { equals: published } }] }) {
      id
      type
      name
      shortDescription
      launchUrl
      categories(orderBy: { weight: asc }) {
        id
        name
      }
      logoImage {
        url
        width
        height
      }
      landscapeThumbnail {
        url
      }
      backgroundImage {
        url
      }
      thumbnailVideo {
        url
      }
      backgroundVideo {
        url
      }
    }
  }
`);

export const GetSearchResults = graphql(`
  query GetSearchResults($query: String!) {
    items(
      where: {
        AND: [
          {
            OR: [
              { name: { contains: $query, mode: insensitive } }
              { categories: { some: { name: { contains: $query, mode: insensitive } } } }
              { tags: { some: { name: { contains: $query, mode: insensitive } } } }
            ]
          }
          { status: { equals: published } }
        ]
      }
      orderBy: { publishedAt: desc }
    ) {
      id
      type
      name
      shortDescription
      launchUrl
      categories(orderBy: { weight: asc }) {
        id
        name
      }
      tags {
        id
        name
      }
      landscapeThumbnail {
        url
      }
      thumbnailVideo {
        url
      }
    }
  }
`);

export const GetItemDetail = graphql(`
  query GetItemDetail($itemId: ID!) {
    items(where: { AND: [{ id: { equals: $itemId } }, { status: { equals: published } }] }) {
      id
      type
      name
      description
      launchUrl
      igdbId
      twitchId
      developer
      publisher
      categories(orderBy: { weight: asc }) {
        id
        name
      }
      landscapeThumbnail {
        url
      }
      trailerVideo {
        url
      }
      backgroundVideo {
        url
      }
    }
  }
`);

export const GetCategories = graphql(`
  query GetCategories {
    categories(orderBy: { weight: asc }) {
      id
      name
      landscapeThumbnail {
        url
      }
      portraitThumbnail {
        url
      }
      backgroundImage {
        url
      }
    }
  }
`);

export const GetCategoryBackgroundImage = graphql(`
  query GetCategoryBackgroundImage($categoryId: ID!) {
    category: categories(where: { id: { equals: $categoryId } }) {
      id
      backgroundImage {
        url
      }
    }
  }
`);
