import { useEffect } from 'react';
import { ticker } from '../utils/ticker';

const SCROLLBAR_SIZE_CHECK_LISTENER_ID = Symbol();
const CARD_SIZE_CHECK_LISTENER_ID = Symbol();
const CARD_TYPES = [
  'landscape-medium',
  'landscape-large',
  'portrait-medium',
  'portrait-large',
] as const;
const CARD_MEASURE_DATA = new Map<
  (typeof CARD_TYPES)[number],
  { element: HTMLElement; width: number; height: number }
>();

function initializeScrollbarSizes(el: HTMLElement) {
  const w = el.offsetWidth - el.clientWidth;
  const h = el.offsetHeight - el.clientHeight;
  const { style } = document.documentElement;
  style.setProperty('--g--root-scrollbar-width', `${w}px`);
  style.setProperty('--g--root-scrollbar-height', `${h}px`);
}

function updateScrollbarSizes(el: HTMLElement) {
  let w = 0;
  let h = 0;

  ticker.once(
    'read',
    () => {
      w = el.offsetWidth - el.clientWidth;
      h = el.offsetHeight - el.clientHeight;
    },
    SCROLLBAR_SIZE_CHECK_LISTENER_ID,
  );

  ticker.once(
    'write',
    () => {
      const { style } = document.documentElement;
      style.setProperty('--g--root-scrollbar-width', `${w}px`);
      style.setProperty('--g--root-scrollbar-height', `${h}px`);
    },
    SCROLLBAR_SIZE_CHECK_LISTENER_ID,
  );
}

function initializeCardSizes() {
  CARD_TYPES.forEach((cardType) => {
    let measureData = CARD_MEASURE_DATA.get(cardType);
    let element = measureData?.element;

    // Create card measure element if it doesn't exist.
    if (!element) {
      element = document.createElement('div');
      const { style } = element;
      style.position = 'absolute';
      style.left = '-100%';
      style.top = '-100%';
      style.pointerEvents = 'none';
      style.visibility = 'hidden';
      style.width = `var(--g--card-${cardType}-width)`;
      style.aspectRatio = `var(--g--card-${cardType}-aspect-ratio)`;

      measureData = { element, width: 0, height: 0 };
      CARD_MEASURE_DATA.set(cardType, measureData);

      document.body.appendChild(element);
    }

    // Compute card dimensions.
    const { width, height } = element.getBoundingClientRect();

    // Update measure data dimensions.
    measureData!.width = width;
    measureData!.height = height;

    // Update CSS variables.
    const { style } = document.documentElement;
    style.setProperty(`--g--card-${cardType}-width-unitless`, `${width}`);
    style.setProperty(`--g--card-${cardType}-height-unitless`, `${height}`);
  });
}

function updateCardSizes() {
  ticker.once(
    'read',
    () => {
      CARD_TYPES.forEach((cardType) => {
        const measureData = CARD_MEASURE_DATA.get(cardType)!;
        const { width, height } = measureData.element.getBoundingClientRect();
        measureData.width = width;
        measureData.height = height;
      });
    },
    CARD_SIZE_CHECK_LISTENER_ID,
  );

  ticker.once(
    'write',
    () => {
      CARD_TYPES.forEach((cardType) => {
        const { width, height } = CARD_MEASURE_DATA.get(cardType)!;
        const { style } = document.documentElement;
        style.setProperty(`--g--card-${cardType}-width-unitless`, `${width}`);
        style.setProperty(`--g--card-${cardType}-height-unitless`, `${height}`);
      });
    },
    CARD_SIZE_CHECK_LISTENER_ID,
  );
}

export function useDynamicCssVariables() {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    const el = document.getElementById('__next');
    if (!el) return;

    const updateVariables = () => {
      updateScrollbarSizes(el);
      updateCardSizes();
    };

    // Compute variables on initial render.
    initializeScrollbarSizes(el);
    initializeCardSizes();

    // Recompute variables on window resize.
    window.addEventListener('resize', updateVariables);

    return () => {
      const { style } = document.documentElement;

      window.removeEventListener('resize', updateVariables);
      ticker.off('read', CARD_SIZE_CHECK_LISTENER_ID);
      ticker.off('write', CARD_SIZE_CHECK_LISTENER_ID);
      ticker.off('read', SCROLLBAR_SIZE_CHECK_LISTENER_ID);
      ticker.off('write', SCROLLBAR_SIZE_CHECK_LISTENER_ID);

      style.removeProperty('--g--root-scrollbar-width');
      style.removeProperty('--g--root-scrollbar-height');

      CARD_TYPES.forEach((cardType) => {
        style.removeProperty(`--g--card-${cardType}-width-unitless`);
        style.removeProperty(`--g--card-${cardType}-height-unitless`);
      });
    };
  }, []);
}
