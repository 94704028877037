import { useEffect, useState, memo, CSSProperties } from 'react';
import { ticker } from '../../utils/ticker';
import styles from './shimmer.module.css';

export type ShimmerProps = {
  isActive?: boolean;
  duration?: number;
  count?: number;
  strength?: number;
};

export function Shimmer({
  isActive = false,
  duration = 600,
  count = Infinity,
  strength = 8,
}: ShimmerProps) {
  const [isReady, setReady] = useState(false);

  // We need to give the browser time to render the component before animating
  // it in. That's why we delay the state change until the next tick.
  useEffect(() => {
    const listenerId = ticker.once('write', () => {
      setReady(true);
    });

    return () => {
      ticker.off('write', listenerId);
    };
  }, []);

  return (
    <div
      className={`${styles.root} ${isReady && isActive ? styles.isActive : ''}`}
      style={
        {
          ['--shimmer--strength']: `${strength}%`,
          ['--shimmer--duration']: `${duration}ms`,
          ['--shimmer--iteration-count']: count === Infinity ? 'infinite' : count.toString(),
        } as CSSProperties
      }
    >
      <div className={styles.wrapper}>
        <div className={styles.ripple}></div>
      </div>
    </div>
  );
}

export const ShimmerMemo = memo(Shimmer);
